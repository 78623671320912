<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img src="/logo.png">
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
              fluid
              :src="imgUrl"
              alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5 pt-3"
      >
        <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
        >
          <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
          >
            Verify your account!
          </b-card-title>
          <b-card-text class="mb-2">
            Please checkout your email for the verification PIN number
          </b-card-text>

          <!-- form -->
          <validation-observer ref="verificationValidation">
            <b-form
                class="auth-login-form mt-2"
                @submit.prevent
            >
              <!-- email -->
              <b-form-group
                  label="E-Mail"
                  label-for="email"
              >
                <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required"
                >
                  <b-form-input
                      id="email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="email"
                      readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- pin -->
              <b-form-group
                  label="PIN"
                  label-for="pin-number"
              >
                <validation-provider
                    #default="{ errors }"
                    name="PIN"
                    rules="required"
                >
                  <b-form-input
                      id="pin-number"
                      v-model="pinNumber"
                      :state="errors.length > 0 ? false:null"
                      name="pin-number"
                      placeholder="1234"
                      :disabled="disableInputs"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'auth-forgot-password-v2'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                >
                  <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                        :disabled="disableInputs"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                  type="submit"
                  variant="primary"
                  block
                  @click="validationForm"
              >
                Verify
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <b-card-text
                role="button"
                @click="resend()"
            >
              <span>Resend code</span>
            </b-card-text>
          </b-card-text>
          <b-card-text class="text-center mt-2">
            <span>Already verified? </span>
            <b-link :to="{name:'login'}">
              <span>&nbsp;Login here</span>
            </b-link>
          </b-card-text>

        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      disableInputs: false,
      pinNumber: '',
      userEmail: this.$store.state.auth.resetPasswordEmail,
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.verificationValidation.validate().then(success => {
        if (success) {
          this.disableInputs = true;
          this.$http.post('/reset-password', {
            email: this.userEmail,
            code: this.pinNumber,
            password: this.password,
            password_confirmation: this.password,
          }).then(res => {
            if (res.data.value.status === 1) {
              const user = res.data.value
              this.$store.commit('auth/SET_AUTHENTICATED', true)
              this.$store.commit('auth/SET_USER', user)
              this.$store.commit('auth/UPDATE_TOKEN', user.token)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Password reset successfully!',
                  icon: 'SuccessIcon',
                  variant: 'success',
                },
              })
              window.location.href = '/';
            } else {
              this.disableInputs = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'E-Mail failed verification!',
                  icon: 'ErrorIcon',
                  variant: 'error',
                },
              })
            }
          }).catch(() => {
            this.disableInputs = false;
            this.$store.commit('auth/SET_USER', [])
            this.$store.commit('auth/SET_AUTHENTICATED', false)
            this.$store.commit('auth/UPDATE_TOKEN', null)
          })
        }
      })
    },
    resend() {
      this.$http.post('/resend', {
        email: this.$store.state.auth.user.email,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'E-Mail send successfully.',
            icon: 'SuccessIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        this.$store.commit('auth/SET_USER', [])
        this.$store.commit('auth/SET_AUTHENTICATED', false)
        this.$store.commit('auth/UPDATE_TOKEN', null)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
